export const QuestionData = [
  {
    id: 1,
    title: "드디어 내 생일! \n 약속을 잡을 때 나는?",
    img: "img/image1.png",
    answera: "생일이니까 다같이!\n  여러명이서 모인다.",
    answerb: "소중한 사람들끼리 소소하게!\n  1~2명씩 소수로 모인다.",
    type: "EI",
  },
  {
    id: 2,
    title: "친구를 위한 케이크! \n 어떻게 주문하지?",
    img: "img/image2.png",
    answera: "시트는 초코, 레터링은 사진찍어서! \n 최대한 자세하게 말한다.",
    answerb: "전체적으로 귀엽게요! \n 분위기만 말하고 전문가에게 맡긴다.",
    type: "SN",
  },
  {
    id: 3,
    title: "애인이 내 생일을 까먹었다.\n 그럴 때 나는?",
    img: "img/image3.png",
    answera: "이유를 물어본다.\n 납득 가능하면 ok",
    answerb: "왜지...? \n 전화를 꺼둔다",
    type: "TF",
  },
  {
    id: 4,
    title: "중요한 기념일이 일주일 남았을 때\n 나는?",
    img: "img/image4.png",
    answera:
      "우선 장소부터 예약하고 다음은 케이크 예약,,\n 차근차근 하나씩 예약을 시작한다",
    answerb: "3일 정도면 충분하지,\n 더 알아본 뒤 한번에 예약한다",
    type: "JP",
  },
  {
    id: 5,
    title: "생각치 못한 보너스가 생겼을 때 나는?",
    img: "img/image5.png",
    answera: "장바구니 털기!\n 쇼핑몰 장바구니에 담았던 것들을 하나씩 산다.",
    answerb: "오늘은 flex!\n 백화점을 싹 돌면서 사고싶은걸 산다.",
    type: "JP",
  },
  {
    id: 6,
    title: "선물로 뭐 가지고 싶어~?\n 친구가 생일선물을 물어볼 때 나는?",
    img: "img/image6.png",
    answera: "나 ~ 필요해!\n 원하는걸 정확히 이야기 한다.",
    answerb: "너가 주고 싶은거!\n 나를 생각하며 골라달라고 한다.",
    type: "TF",
  },
  {
    id: 7,
    title: "너 요새 힘들어 보여서 케이크 사왔어\n 라는 말을 듣는다면?",
    img: "img/image7.png",
    answera: "내가? 힘들어보인다고?? 어디가?(순수)\n라고 말한다",
    answerb: "헐..진짜?? 완전 감동 고마워ㅠㅠ\n라고 말한다 ",
    type: "TF",
  },
  {
    id: 8,
    title: "나의 생일날 더욱 감동받는건?",
    img: "img/image8.png",
    answera: "전화로! \n  제일 먼저 전화로 축하해줄 때!",
    answerb: "문자로! \n 마음을 담아 길게 쓴 편지를 카톡을 받을때!",
    type: "EI",
  },
  {
    id: 9,
    title: "오늘은 일요일!\n 친구가 맛있는거 사줄게 나와! 한다면 나는?",
    img: "img/image9.png",
    answera: "그래~ 어디서 만날까?\n 가고싶었던 곳 여기저기 찾아본다",
    answerb: "아...(쉬고싶은데)\n 어떻게 거절할 지 고민한다",
    type: "EI",
  },
  {
    id: 10,
    title: "밥먹으러 가다가 괜찮은 음식점을 발견했을 때\n 나는?",
    img: "img/image10.png",
    answera: "목적지가 우선이다.\n 원래 가려고 했던 음식점으로 간다",
    answerb: "내 촉을 믿는다.\n 새로 발견한 음식점으로 간다.",
    type: "SN",
  },
  {
    id: 11,
    title: "여행지로 가는중.. 비행기가 흔들릴 때 \n 나는?",
    img: "img/image11.png",
    answera: "어지럽다.. \n 멀미날 수 있으니까 가만히 있어야지",
    answerb: "혹시 추락하면 어떡하지? \n 구명조끼는? 수영하면 얼마나 버티지?",
    type: "SN",
  },
  {
    id: 12,
    title: "다음주 기념일에 제주도 놀러갈까?",
    img: "img/image12.png",
    answera: "갑자기??\n 우선 비행기표 최저가부터 알아본다.",
    answerb: "좋아! 가서 바다보고 맛있는거 먹으면 딱이지\n 느긋하게 생각한다.",
    type: "JP",
  },
];
