export const ResultData = [
  {
    id: 1,
    name: "아이스크림",
    best: "ESTJ",
    title: "시원시원 속시원한",
    desc: [
      "시원시원하고 현실적이에요",
      "매사에 책임감이 강하고 철저한 확신의 리더상이에요",
      "하지만 표현이 서툴러 다소 차가워 보일 수도 있어요",
      "나한테 지적하려면 정확한 근거가 필요해요",
    ],
    good: "ISTP",
    bad: "INFP",
    image: "img/result/result_img5.png",
  },
  {
    id: 2,
    name: "약과",
    best: "ESFJ",
    title: "남녀노소 좋아하는",
    desc: [
      "친화력이 좋고 주변사람들에게 인기쟁이에요",
      "현실적이고 안정적이에요",
      "하지만 가끔 감정기복이 날뛸 때가 있어요",
      "잔걱정이 많아서 잔소리를 할때도 있어요",
    ],
    good: "ISTP",
    bad: "INFJ",
    image: "img/result/result_img6.png",
  },
  {
    id: 3,
    name: "푸딩",
    best: "ISFP",
    title: "부들부들 말랑말랑",
    desc: [
      "마음이 순하고 정이 많아요",
      "남들에게 민폐끼치는 거 싫어하고, 싫어도 내색하지 않아요",
      "고집은 있지만, 고집 세보이는거 싫어요",
      "가끔 일을 미룰 수 있을때까지 미뤄요, 하지만 한번 마음 먹으면 제대로 해요",
    ],
    good: "ESTJ",
    bad: "INTP",
    image: "img/result/result_img2.png",
  },
  {
    id: 4,
    name: "레터링 케이크",
    best: "INTP",
    title: "생각이 뭉게뭉게",
    desc: [
      "논리적이며 아이디어가 많아요",
      "주관이 뚜렷하고 항상 생각하고 표현해요",
      "잡담은 싫어하지만 관심분야에서는 말이 많아요",
      "남들한테는 관심이 별로 없어요",
    ],
    good: "ESFJ",
    bad: "ESFP",
    image: "img/result/result_img16.png",
  },
  {
    id: 5,
    name: "컵케이크",
    best: "ISFJ",
    title: "편하고 부드러운",
    desc: [
      "온화하고 차분하며 섬세해요",
      "현실적이고 안정적인 판단을 잘해요",
      "남의 얘기는 잘 들어주지만 내 얘기는 잘 안해요",
      "잘난척하는거 진짜 싫어해요",
    ],
    good: "ENTP",
    bad: "ENTJ",
    image: "img/result/result_img15.png",
  },
  {
    id: 6,
    name: "개성주악",
    best: "ISTP",
    title: "겉은바삭 속은반전",
    desc: [
      "과묵하지만 적응력이 뛰어나요",
      "현실감각이 뛰어나고 임기응변에 능해요",
      "보통 조용하지만 필요하다면 사교적이에요",
      "마음에 없는 소리하는거 싫어해요 ",
    ],
    good: "ENFJ",
    bad: "ENFP",
    image: "img/result/result_img7.png",
  },
  {
    id: 7,
    name: "마카롱",
    best: "INFJ",
    title: "어디서든 꼭필요한",
    desc: [
      "눈치가 빠르고 통찰력이 있어요",
      "옳다고 확신이 생긴 신념은 끝까지 밀고나가요",
      "내가 생각하는 걸 완벽히 공감해줄 사람은 없다고 생각해요",
      "신중하지만 한번 실망하면 빠르게 정리해요",
    ],
    good: "ESTP",
    bad: "ESTJ",
    image: "img/result/result_img13.png",
  },
  {
    id: 8,
    name: "2단 케이크",
    best: "ENFP",
    title: "반짝반짝 화려한",
    desc: [
      "친구가 많고 긍정적이에요",
      "한번 꽂히는 일은 무조건 해야해요",
      "관종같아도 은근 내향적이고 독립적이에요",
      "질리는게 빠르고 권태기가 잘 와요",
    ],
    good: "INFJ",
    bad: "ISTJ",
    image: "img/result/result_img14.png",
  },
  {
    id: 9,
    name: "까눌레",
    best: "INTJ",
    title: "단단하고 속이 꽉찬",
    desc: [
      "독립적이고 나의 능력을 믿어요",
      "원리원칙적이고 시간관리, 돈관리 완벽해요",
      "사람에게 정붙이는게 오래걸려요",
      "감정이 서툰게 아니라 신경을 끄는편이에요",
    ],
    good: "ENFP",
    bad: "ISFJ",
    image: "img/result/result_img12.png",
  },
  {
    id: 10,
    name: "초콜릿",
    best: "ESTP",
    title: "마냥 달지는 않은",
    desc: [
      "스릴과 모험을 좋아해요",
      "선입견이 없고 개방적이며 주변에 관심이 많아요",
      "갈등이 심해질수록 냉정해져요",
      "해야하는것보다는 당장 내가 하고싶은것을 먼저해요",
    ],
    good: "ISFJ",
    bad: "ENFJ",
    image: "img/result/result_img9.png",
  },
  {
    id: 11,
    name: "카라멜사과",
    best: "ENTP",
    title: "호불호호 개성강한",
    desc: [
      "유행을 따라가지 않고 선도해요",
      "통통튀는 아이디어가 많고 카리스마가 있어요",
      "도전이 없는일에는 흥미가 없어요",
      "토론이나 논쟁하는거 좋아해요",
    ],
    good: "INTJ",
    bad: "ESFP",
    image: "img/result/result_img10.png",
  },
  {
    id: 12,
    name: "크로와상",
    best: "ISTJ",
    title: "어딜가나 제일근본",
    desc: [
      "책임감이 강하고 원칙을 지켜요",
      "한번 시작하면 목표한건 무조건 끝내요",
      "시간약속 안지키는거 정말 싫어해요",
      "누구시키는것보다 내가 하는게 마음편해요",
    ],
    good: "ISTJ",
    bad: "INFJ",
    image: "img/result/result_img8.png",
  },
  {
    id: 13,
    name: "츄러스",
    best: "ESFP",
    title: "내가 빠지면 섭하지",
    desc: [
      "붙임성좋고 에너지가 넘쳐요",
      "요즘 유행은 다 꿰고 있어서 트렌디해요",
      "가끔은 우주최강 오지라퍼에요",
      "싸우는건 싫어하지만 싸워서 지는게 더 싫어요",
    ],
    good: "ESTP",
    bad: "INTJ",
    image: "img/result/result_img11.png",
  },
  {
    id: 14,
    name: "찹쌀떡",
    best: "ENTJ",
    title: "찰떡같이 알아듣는",
    desc: [
      "자존감이 높고 효율적이에요",
      "사교적이며 솔직해서 리더로 딱이에요",
      "가끔 엉뚱하다는 소리를 들어요",
      "해보지도 않고 못한다고 하는거 제일 싫어해요",
    ],
    good: "INTP",
    bad: "ESFJ",
    image: "img/result/result_img1.png",
  },
  {
    id: 15,
    name: "솜사탕",
    best: "ENFJ",
    title: "다정함으로 살살녹는",
    desc: [
      "호기심이 많고 정의로워요",
      "나 스스로에 대해 잘 알고  걱정도 많지만 티를 잘 내지 않아요",
      "갈등은 싫어해요. 평화로운게 좋아요",
      "하지만 고집도 세서 내가 마음먹은건 꼭 해야해요",
    ],
    good: "INFP",
    bad: "ESTJ",
    image: "img/result/result_img4.png",
  },
  {
    id: 16,
    name: "마시멜로",
    best: "INFP",
    title: "폭신하고 부드러운",
    desc: [
      "이해심이 많고 적응력이 좋아요",
      "좋아하는 사람을 기쁘게 하는것을 즐거워해요",
      "하지만 한번 싫은건 끝까지 싫어해요",
      "애매모호한 정보에는 하나하나 구체적으로 질문해요",
    ],
    good: "INFJ",
    bad: "ESTP",
    image: "img/result/result_img3.png",
  },
];
